import React from 'react'
import { graphql, Link } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import { Container, Row, Col } from 'react-bootstrap'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Breadcrumbs from '../components/breadcrumbs'
import Prose from '../components/prose'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { slug, previous, next } = pageContext
  const meta = data.markdownRemark.frontmatter
  const metaDesc = meta.description || meta.summary

  return (
    <Layout currentSection="blog">
      <SEO
        title={meta.title}
        description={metaDesc}
        meta={[
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
        ]}
      >
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}/playbook${data.markdownRemark.fields.slug}`}
        />
      </SEO>

      <Section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} lg={8}>
              <Breadcrumbs>
                <Breadcrumbs.Link to="/blog/">
                  Blog
                </Breadcrumbs.Link>
              </Breadcrumbs>

              <article className="mb-5">
                <h1>
                  {meta.title}
                </h1>

                <p>
                  {meta.date}
                  {' '}&middot;{' '}
                  {data.site.siteMetadata.title}
                </p>

                <p className="lead">
                  {metaDesc}
                </p>

                <Prose hasRawHtml={true}>
                  {data.markdownRemark.html}
                </Prose>
              </article>

              <Bio />

              <div className="my-5">
                <h2>Leave a comment</h2>
                <DiscussionEmbed shortname='chrisdpeters' config={{
                  identifier: slug,
                  title: post.frontmatter.title,
                }} />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col xs={12}>
              <nav>
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li style={{
                    maxWidth: '45%',
                  }}>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>

                  <li style={{
                    maxWidth: '45%',
                  }}>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPost($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
      }
      fields {
        slug
      }
    }
  }
`
